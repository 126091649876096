import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

const GLobalSpacing = graphql`
  query {
    prismic {
      allLayouts {
        edges {
          node {
            spacer_value
          }
        }
      }
    }
  }
`;

function Spacer(props) {
  const { children } = props;
  return (
    <StaticQuery
      query={`${GLobalSpacing}`}
      render={data => (
        <Box pt={data.prismic.allLayouts.edges[0].node.spacer_value}>
          {children}
        </Box>
      )}
    />
  );
}

Spacer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Spacer;
