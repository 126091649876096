import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Spacer from 'components/spacer';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/styles';
import Link from 'gatsby-link';
import Logo from 'images/RECH2022BrandDark.svg';
import linkResolver from 'utils/linkResolver';

import { Typography } from '@material-ui/core';

const FooterQuery = graphql`
  query {
    prismic {
      allLayouts {
        edges {
          node {
            title
            secondary_nav {
              title
              link {
                ... on PRISMIC_Standard_page {
                  _meta {
                    uid
                    type
                    id
                  }
                }
                ... on PRISMIC_Contact_page {
                  _meta {
                    id
                    type
                    uid
                  }
                }
                ... on PRISMIC_Article {
                  _meta {
                    id
                    type
                    uid
                  }
                }
                ... on PRISMIC_Homepage {
                  _meta {
                    id
                    type
                    uid
                  }
                }
                ... on PRISMIC_Basic_page {
                  _meta {
                    id
                    type
                    uid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const styles = theme => ({
  footer: {
    backgroundColor: '#fff',
  },
  divider: {
    width: '1px',
    height: '100%',
    backgroundColor: theme.palette.primary.main,
  },
  horzDivider: {
    height: '1px',
    backgroundColor: theme.palette.primary.main,
  },
  light: {
    color: theme.palette.primary.main,
    letterSpacing: '2.3px',
    lineHeight: '24px',
    textTransform: 'uppercase',
  },
  bold: {
    fontWeight: 600,
  },
  lg: {
    fontSize: '24px',
  },
  link: {
    textDecoration: 'none',
  },
  socialicon: {
    color: theme.palette.primary.main,
    height: '30px',
    width: '30px',
    marginRight: '10px',
  },
  navItem: {
    listStyle: 'none',
  },
  navLink: {
    textDecoration: 'none',
    transition: 'opacity 0.3s ease-in-out',
    '&:hover': {
      opacity: 0.6,
      '& .underline': {
        opacity: 1,
      },
    },
  },
  smlLogo: {
    width: '120px',
    height: 'auto',
  },
});

function Footer(props) {
  const { classes } = props;
  return (
    <StaticQuery
      query={`${FooterQuery}`}
      render={data => (
        <Spacer>
          <Box component="footer" className={classes.footer} py={10}>
            <Container maxWidth={false}>
              <Grid container>
                <Grid item xs={12}>
                  <Grid container>
                    <Box
                      component={Grid}
                      display="flex"
                      flexDirection="row"
                      item
                      xs={3}
                    >
                      <Box
                        className={classes.divider}
                        display={{ xs: 'none', md: 'block' }}
                      />
                      <Box ml={{ xs: 0, md: 6 }}>
                        <Box>
                          <a
                            href="https://realestatecapitalholdings.com/"
                            rel="noopener noreferrer"
                            target="_blank"
                            className={classes.link}
                          >
                            <Logo className={classes.smlLogo} />
                          </a>
                        </Box>
                      </Box>
                    </Box>
                    <Box component={Grid} item xs={7}>
                      {data.prismic.allLayouts.edges[0].node.secondary_nav.map(
                        ({ title, link }, key) => (
                          <Box
                            key={key}
                            component="li"
                            px={2}
                            mb={4}
                            className={classes.navItem}
                          >
                            <Link
                              className={classes.navLink}
                              to={link ? linkResolver(link._meta) : ''}
                            >
                              <Typography
                                component="span"
                                variant="subtitle1"
                                color="primary"
                              >
                                {title[0].text}
                              </Typography>
                              <Box
                                component="span"
                                className={`${classes.underline} underline`}
                              />
                            </Link>
                          </Box>
                        )
                      )}
                    </Box>
                    <Box
                      component={Grid}
                      item
                      display="flex"
                      flexDirection="row"
                      xs={12}
                      md={2}
                    >
                      <Box
                        className={classes.divider}
                        mr={5}
                        display={{ xs: 'none', md: 'block' }}
                      />
                      <Box>
                        <Box mb={2}>
                          <a
                            href="https://www.hayfieldhomes.co.uk"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={classes.link}
                          >
                            <Typography
                              component="span"
                              variant="subtitle1"
                              className={`${classes.light} ${classes.bold} ${classes.lg}`}
                            >
                              Hayfield
                            </Typography>
                          </a>
                        </Box>
                        <Box mb={2}>
                          <a
                            href="https://www.livingspacehousing.co.uk"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={classes.link}
                          >
                            <Typography
                              component="span"
                              variant="subtitle1"
                              className={`${classes.light} ${classes.bold} ${classes.lg}`}
                            >
                              Living Space
                            </Typography>
                          </a>
                        </Box>
                        <Box mb={2}>
                          <a
                            href="http://www.terrastrategic.co.uk"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={classes.link}
                          >
                            <Typography
                              component="span"
                              variant="subtitle1"
                              className={`${classes.light} ${classes.bold} ${classes.lg}`}
                            >
                              Terra
                            </Typography>
                          </a>
                        </Box>
                        <Box mb={2}>
                          <a
                            href="https://www.merrion-holdings.com/"
                            rel="noopener noreferrer"
                            target="_blank"
                            className={classes.link}
                          >
                            <Typography
                              component="span"
                              variant="subtitle1"
                              className={`${classes.light} ${classes.bold} ${classes.lg}`}
                            >
                              Merrion
                            </Typography>
                          </a>
                        </Box>
                        <Box mb={2}>
                          <a
                            href="https://www.gateway-land.co.uk/"
                            rel="noopener noreferrer"
                            target="_blank"
                            className={classes.link}
                          >
                            <Typography
                              component="span"
                              variant="subtitle1"
                              className={`${classes.light} ${classes.bold} ${classes.lg}`}
                            >
                              Gateway
                            </Typography>
                          </a>
                        </Box>
                        <Box>
                          <a
                            href="https://www.verdanthomes.co.uk/"
                            rel="noopener noreferrer"
                            target="_blank"
                            className={classes.link}
                          >
                            <Typography
                              component="span"
                              variant="subtitle1"
                              className={`${classes.light} ${classes.bold} ${classes.lg}`}
                            >
                              Verdant
                            </Typography>
                          </a>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>

              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                mt={8}
              >
                <Typography
                  component="a"
                  variant="subtitle2"
                  href="https://www.inetdigital.co.uk"
                  color="primary"
                  className={classes.refLink}
                >
                  Website by iNet Digital
                </Typography>
              </Box>
            </Container>
          </Box>
        </Spacer>
      )}
    />
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footer);
