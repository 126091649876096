import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import { Context } from 'store/provider';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import Logo from 'images/RECH2022BrandWhite.svg';
import MenuBurger from 'images/menuburger.svg';
import MenuBurgerReverse from 'images/menuburgerreverse.svg';

const styles = theme => ({
  header: {
    width: '100%',
    zIndex: 2,
    position: 'absolute',
  },
  icon: {
    color: theme.palette.text.white,
    '&.blueIcon': {
      color: theme.palette.primary.main,
    },
  },
  menuBtn: {
    background: 'transparent',
    border: 0,
    padding: 0,
    cursor: 'pointer',
    transform: 'scale(1)',
    transition: 'transform 0.3s ease-in-out',
    outline: 0,
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  navItem: {
    listStyle: 'none',
  },
  navLink: {
    textDecoration: 'none',
    transition: 'opacity 0.3s ease-in-out',
    '&:hover': {
      opacity: 0.6,
      '& .underline': {
        opacity: 1,
      },
    },
  },
  logo: {
    height: 'auto',
    width: '100px',
    [theme.breakpoints.up('md')]: {
      height: 'auto',
      width: '100px',
    },
  },
  underline: {
    display: 'block',
    height: '1px',
    width: '100%',
    background: theme.palette.text.white,
    opacity: 0,
    transition: 'opacity 0.3s ease-in-out',
  },
  ident: {
    height: '30px',
  },
  menuPinned: {
    display: 'none',
    width: '100%',
  },
  smlLogo: {
    width: '80px',
    height: 'auto',
  },
});

function Header(props) {
  const { classes, themeColor, hideLogo } = props;
  const [state, dispatch] = useContext(Context);

  const menuToggle = () => {
    dispatch({ type: 'SET_MENU', payload: !state.menu });
  };
  const groupMenuToggle = () => {
    dispatch({ type: 'SET_GROUP_MENU', payload: !state.menu });
  };

  return (
    <Box py={6} className={`${classes.header} header`}>
      <Container maxWidth={false}>
        <Box
          component={Grid}
          container
          className="menu--block"
          justifyContent="space-between"
        >
          <Grid item xs={2}>
            <Box
              component="button"
              display="flex"
              alignItems="center"
              onClick={menuToggle}
              className={classes.menuBtn}
            >
              <Box mr={1}>
                {themeColor == 'white' ? (
                  <MenuBurgerReverse className={classes.icon} />
                ) : (
                  <MenuBurger className={classes.icon} />
                )}
              </Box>
              <Typography
                component="span"
                variant="subtitle2"
                color={`${themeColor == 'white' ? 'textPrimary' : 'secondary'}`}
              >
                MENU
              </Typography>
            </Box>
          </Grid>
          {!hideLogo && (
            <Box
              component={Grid}
              item
              xs={2}
              className="header-block-right"
              display="flex"
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              <Link to="/">
                <Logo className={classes.smlLogo} />
              </Link>
            </Box>
          )}
        </Box>

        <Grid container className={`${classes.menuPinned} menu--pinned`}>
          <Box component={Grid} item xs display="flex" alignItems="center">
            <Box
              component="button"
              display="flex"
              alignItems="center"
              onClick={menuToggle}
              className={classes.menuBtn}
            >
              <Box mr={1}>
                <MenuBurgerReverse className={classes.icon} />
              </Box>
              <Typography
                component="span"
                variant="subtitle2"
                color="textPrimary"
              >
                MENU
              </Typography>
            </Box>
            <Box
              component={Grid}
              item
              xs
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Logo className={classes.logo} />
            </Box>
          </Box>
        </Grid>
      </Container>
    </Box>
  );
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
  themeColor: PropTypes.string,
  developmentPageMenu: PropTypes.object,
  homeTypePageMenu: PropTypes.object,
  hideMenu: PropTypes.bool,
  hideLogo: PropTypes.bool,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Header);
